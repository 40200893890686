<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
              <div class="col-12 section_title">CREAR ORDEN DE TRABAJO</div>
            </div>
          </template>

          <template v-slot:end>
            <Button
              label="AGREGAR"
              icon="pi pi-plus"
              class="p-button-info mr-2"
              @click="save()"
            />
          </template>
        </Toolbar>

        <div class="">
          <div class="">
            <div class="formgrid grid">
              <div class="field col">
                <label for="detail">PIEZA</label>

                <Dropdown
                  id="client"
                  v-model="form.piece"
                  :options="pieces"
                  class="w-full"
                  filter="true"
                  filterBy="name"
                  filterPlaceholder="Buscar..."
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                />
              </div>
              <div class="field col">
                <label for="detail">CANTIDAD</label>
                <InputText
                  v-model="form.quantity"
                  id="detail"
                  type="text"
                  class="inputfield w-full"
                />
              </div>
            </div>
          </div>
          <div class="">
            <div class="formgrid grid">
              <div class="field col">
                <label for="detail">MATERIAL</label>
                <Dropdown
                  id="client"
                  v-model="form.material"
                  :options="materials"
                  class="w-full"
                  filter="true"
                  filterBy="name"
                  filterPlaceholder="Buscar..."
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.client }"
                />
              </div>
              <div class="field col">
                <label for="detail">MAQUINA</label>
                <Dropdown
                  id="client"
                  v-model="form.machine_id"
                  :options="machines"
                  class="w-full"
                  filter="true"
                  filterBy="name"
                  filterPlaceholder="Buscar..."
                  optionLabel="name"
                  optionValue="id"
                  required="true"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.client }"
                />
              </div>
              <div class="field col">
                <label for="detail">TRATAMIENTO</label>
                <Dropdown
                  id="client"
                  v-model="form.tratamiento"
                  :options="tratamientos"
                  class="w-full"
                  filter="true"
                  filterBy="name"
                  filterPlaceholder="Buscar..."
                  optionLabel="name"
                  optionValue="name"
                  autofocus
                  :class="{ 'p-invalid': submitted && !form.client }"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CrudService from "../../services/crud.service";
import axios from "axios";

export default {
  data() {
    return {
      routeName: null,
      form: {
        material: 0,
        piece: 0,
        piece: " ",
        tratamiento: 0,
      },
      submitted: false,
      validationErrors: null,
      planes: [],
      machines: [],
      materials: [],
      pieces: [],
      selectedPlanes: [],
      tratamientos: [
        { id: 1, name: "Tratamiento 1" },
        { id: 2, name: "Tratamiento 2" },
        { id: 3, name: "Tratamiento 3" },
        { id: 4, name: "Tratamiento 4" },
      ],
    };
  },

  created() {
    // get route name
    this.routeName = this.$route.name;
  },
  mounted() {
    this.getPlanes();
    this.getMachines();
    this.getMaterials();
    this.getPieces();
  },
  methods: {
    getPlanes() {
      // this.CrudService.getCRUD("planes").then((response) => {
      //   this.planes = response.data;
      // });
    },
    getMachines() {
      CrudService.getCRUD("api/maquinaria").then((response) => {
        this.machines = response;
      });
    },
    getMaterials() {
      CrudService.getCRUD("api/material").then((response) => {
        this.materials = response;
      });
    },
    getPieces() {
      CrudService.getCRUD("api/pieza").then((response) => {
        this.pieces = response;
      });
    },
    displayErrors(errors) {
      for (let key in errors) {
        this.validationErrors.push({ field: key, message: errors[key] });

        let error = errors[key];
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: error[0],
          life: 3000,
        });
      }
    },
    save() {

      this.submitted = true;
      this.form.production_id = this.$route.params.id;
      this.form.number = "0";
      this.form.status = 0;
      this.form.time = 0;
      

 
      CrudService.createCRUD("api", "orden", this.form).then((data) => {
        console.log(data);

        this.validationErrors = [];
        if (data.status == 400) {
          this.displayErrors(data.data);
        } else {
          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Creado correctamente.",
            life: 3000,
          });

          // wait 2 secs
          setTimeout(() => {
            this.$router.push("/produccion/"+this.$route.params.id);
          }, 1000);
        }
      });
    },
  },
};
</script>